<template>
  <div class="main-area">
    <div class="container full-height position-static">
      <section class="left-section full-height">
        <a class="logo" href="#">
          <h2>OTAKUDRIVE</h2>
          <!-- <img src="images/logo-black.png" alt="Logo" /> -->
        </a>

        <div class="display-table">
          <div class="display-table-cell">
            <div class="main-content">
              <h1 class="title">
                <b>Under Construction</b>
              </h1>
              <p>
                Our website is currently undergoing scheduled maintenance. We
                Should be back shortly. Thank you for your patience.
              </p>

              <div class="email-input-area">
                <form method="post">
                  <input
                    class="email-input"
                    name="email"
                    type="text"
                    placeholder="Enter your email"
                  />
                  <button class="submit-btn" name="submit" type="submit">
                    <b>NOTIFY US</b>
                  </button>
                </form>
              </div>
              <!-- email-input-area -->

              <p class="post-desc">
                Sign up now to get early notification of our lauch date!
              </p>
            </div>
            <!-- main-content -->
          </div>
          <!-- display-table-cell -->
        </div>
        <!-- display-table -->

        <ul class="footer-icons">
          <li>Stay in touch :</li>
          <li>
            <a href="https://www.facebook.com/otakudriveth/">
              <i class="ion-social-facebook"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i class="ion-social-twitter"></i>
            </a>
          </li>
          <!-- <li>
            <a href="#">
              <i class="ion-social-googleplus"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i class="ion-social-instagram-outline"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i class="ion-social-pinterest"></i>
            </a>
          </li> -->
        </ul>
      </section>
      <!-- left-section -->

      <section
        class="right-section"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/background.jpg') + ')'
        }"
      >
        <div class="display-table center-text">
          <div class="display-table-cell">
            <div id="rounded-countdown">
              <div class="countdown" data-remaining-sec="2000000"></div>
            </div>
          </div>
          <!-- display-table-cell -->
        </div>
        <!-- display-table -->
      </section>
      <!-- right-section -->
    </div>
    <!-- container -->
  </div>
  <!-- main-area -->
</template>

<script>
export default {
  name: "Constryction",
  props: {
    msg: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
@import "../assets/css/bootstrap.css";
@import "../assets/css/styles.css";
@import "../assets/css/responsive.css";
@import "../assets/css/ionicons.css";
</style>
