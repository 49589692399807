<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <Construction />
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
import Construction from "./components/Construction.vue";

export default {
  name: "app",
  components: {
    // HelloWorld,
    Construction
  }
};
</script>
